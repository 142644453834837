@property --rotate {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}

:root {
    --card-height: 65vh;
    --card-width: calc(var(--card-height) / 1.5);
}
